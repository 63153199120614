export const TOKEN = 'token'
export const USERINFO = 'userInfo'
// token 时间戳1
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
// export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000

export const TOKEN_TIMEOUT_VALUE = 24 * 3600 * 1000
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#409eff'
// tags
export const TAGS_VIEW = 'tagsView'
export const REMAEKS = 'remarks'
