import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'font-awesome/css/font-awesome.css'
import 'hover.css'
import directives from '@/directives/index'
import print from 'vue3-print-nb'
// import the component
import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
// import the styles
import 'vue3-treeselect/dist/vue3-treeselect.css'
import router from './router'
import store from './store'
import axios from './api/index'
import './permission'
import '@/icons'
import installIcons from '@/icons'
import * as ElIconModules from '@element-plus/icons-vue'
import JsonEditorVue from 'json-editor-vue3'
const app = createApp(App)

Object.keys(ElIconModules).forEach(function (key) {
	app.component(ElIconModules[key].name, ElIconModules[key])
})

installIcons(app)
app.config.globalProperties.$axios = axios
app
	.use(store)
	.use(router)
	.use(ElementPlus, { locale })
	.use(print)
	.use(directives)
	.use(JsonEditorVue)
	.mount('#app')
