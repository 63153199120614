const getters = {
	token: (state) => state.user.token,
	userInfo: (state) => state.user.userInfo,
	remarks: (state) => state.user.remarks,
	sidebarOpen: (state) => state.app.sidebarOpen,
	tips: (state) => state.app.tips,
	routers: (state) => state.menu.routers,
	menus: (state) => state.menu.menus,
	sidebarOpened: (state) => state.app.sidebarOpened,
	/**
	 * @returns true 表示已存在用户信息
	 */
	hasUserInfo: (state) => {
		return JSON.stringify(state.user.userInfo) !== '{}'
	},
}
export default getters
