import {login, getUserInfo} from '../../api/user'
import {setItem, getItem, removeAll} from '@/utils/localStore'
import {TOKEN, USERINFO, REMAEKS} from '@/constant'
import {setTimeStamp} from '@/utils/auth'
import router from '@/router'
import {getRemarks} from '@/api/user'
import {resetRouter} from '@/utils/validate.js'

export default {
    namespaced: true,
    state: () => ({
        token: getItem(TOKEN) || '',
        userInfo: getItem(USERINFO) || {},
        remarks: getItem(REMAEKS) || [],
    }),
    mutations: {
        setToken(state, token) {
            state.token = token
            setItem(TOKEN, token)
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
            setItem(USERINFO, userInfo)
        },
        setRemarks(state, val) {
            state.remarks = val
            setItem(REMAEKS, val)
        },
    },
    actions: {
        login(context, userInfo) {
            const {username, password, captcha} = userInfo
            return new Promise((resolve, reject) => {
                login({
                    username,
                    password,
                    captcha,
                })
                    .then(async (data) => {
                        if (data.code === 200) {
                            // 保存token
                            this.commit('user/setToken', data.data.token)
                            // 保存登录时间
                            setTimeStamp()
                            // 传递登录结果
                            // 获取用户的权限编码
                            const remarks = await getRemarks()
                            this.commit('user/setRemarks', remarks.data)
                            resolve(data)
                        } else {
                            console.log(data.message)
                        }

                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        async getUserInfo() {
            // 获取用户信息根据Authorization
            const {data} = await getUserInfo()
            // 保存当前登录用户信息
            this.commit('user/setUserInfo', data)
        },
        async getRemarks() {
            // 等菜单生成完毕后才执行
            // 获取权限编码 做按钮显示与隐藏
            const remarks = await getRemarks()
            this.commit('user/setRemarks', remarks.data)
        },
        logout() {
            // 清空缓存路由，防止下次登录路由还在
            resetRouter()
            this.commit('user/setToken', '')
            this.commit('user/setUserInfo', {})
            removeAll()
            router.push('/login')
        },
    },
}
