import { LANG, TAGS_VIEW } from '@/constant'
import { getItem, setItem } from '@/utils/localStore'
export default {
	namespaced: true,
	state: () => ({
		sidebarOpened: getItem('sidebarOpened') || false,
		tips: getItem(TAGS_VIEW) || [],
	}),
	mutations: {
		triggerSidebarOpened(state) {
			state.sidebarOpened = !state.sidebarOpened
			setItem('sidebarOpened', state.sidebarOpened)
		},
		setTips(state, val) {
			state.tips = val
			setItem(TAGS_VIEW, val)
		},
		/**
		 * 设置国际化
		 */
		// setLanguage(state, lang) {
		// 	setItem(LANG, lang)
		// 	state.language = lang
		// },
		/**
		 * 添加 tags
		 */
		// addTagsViewList(state, tag) {
		// 	const isFind = state.tagsViewList.find((item) => {
		// 		return item.path === tag.path
		// 	})
		// 	// 处理重复
		// 	if (!isFind) {
		// 		state.tagsViewList.push(tag)
		// 		setItem(TAGS_VIEW, state.tagsViewList)
		// 	}
		// },
		/**
		 * 为指定的 tag 修改 title
		 */
		// changeTagsView(state, { index, tag }) {
		// 	state.tagsViewList[index] = tag
		// 	setItem(TAGS_VIEW, state.tagsViewList)
		// },
		/**
		 * 删除 tag
		 * @param {type: 'other'||'right'||'index', index: index} payload
		 */
		// removeTagsView(state, payload) {
		// 	if (payload.type === 'index') {
		// 		state.tagsViewList.splice(payload.index, 1)
		// 		return
		// 	} else if (payload.type === 'other') {
		// 		state.tagsViewList.splice(
		// 			payload.index + 1,
		// 			state.tagsViewList.length - payload.index + 1
		// 		)
		// 		state.tagsViewList.splice(0, payload.index)
		// 	} else if (payload.type === 'right') {
		// 		state.tagsViewList.splice(
		// 			payload.index + 1,
		// 			state.tagsViewList.length - payload.index + 1
		// 		)
		// 	}
		// 	setItem(TAGS_VIEW, state.tagsViewList)
		// }
	},
	actions: {},
}
