import router from '@/router'
import store from '@/store'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
/**
 * 全局守卫
 */

const whiteRouterPath = ['/login']

let falg = false
router.beforeEach(async (to, from, next) => {
	Nprogress.start()

	if (store.getters.token) {
		// 有token
		if (JSON.stringify(store.getters.userInfo) == '{}') {
			await store.dispatch('user/getUserInfo')
		}
		if (to.path === '/login') {
			next('/')
		} else {
			if (!falg) {
				falg = true
				// 动态路由
				await store.dispatch('menu/routers')
				store.dispatch('menu/menus')
				next({ ...to })
			} else {
				next()
			}
		}
	} else {
		// 没有token
		if (whiteRouterPath.includes(to.path)) {
			next()
		} else {
			next('/login')
		}
	}
})

router.afterEach((to, from) => {
	Nprogress.done()
})
export default router
